<script setup lang="ts">
// import { Vue3Lottie } from 'vue3-lottie';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { creativeStages } from '~/utils/creativeStages';

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndSmaller = breakpoints.smallerOrEqual('md');

const { currentCreative: creative } = storeToRefs(useModuleStore());
const showReviewBorder = computed(() => {
  if (creative.value) {
    return (
      creative.value.inReview &&
      useRoute().fullPath.includes(
        `/creative/${creative.value.slug}/${creativeStages[creative.value.stage].route.split('/')[0]}`
      )
    );
  }
  return false;
});
// const { AIBlob } = useLottieData();
</script>

<template>
  <div class="fixed inset-0 flex overflow-hidden">
    <img
      src="/bg_blur.png"
      alt="bg-blur"
      class="w-[80vw] h-[80vw] fixed -left-[2%] -top-[30%] opacity-[0.02] dark:opacity-[0.012]"
    />

    <div class="fixed inset-0 flex overflow-hidden">
      <!-- Primary Sidbar Wrapper -->
      <HPrimarySidebar v-if="!mdAndSmaller" />

      <!-- Center Content Wrapper -->
      <div class="flex flex-1 w-full min-w-0">
        <div class="flex-col items-stretch relative w-full flex-1 flex">
          <!-- Mobile Header -->
          <HMHeader v-if="mdAndSmaller" />

          <!-- Headers  -->
          <slot name="header" />

          <!-- Sub-headers -->
          <slot name="subHeader" />

          <!-- Stage's nav content -->

          <div
            v-if="showReviewBorder"
            class="h-[2px] w-full border-b border-[#B7B7DF]/60 dark:border-[#424268] md:w-full"
          />

          <div
            class="flex-1 flex flex-col overflow-y-auto w-full items-center justify-start"
            :class="{
              'in-review-border': showReviewBorder,
            }"
          >
            <slot name="content" />
          </div>

          <!-- Breadcrumbs -->
          <div
            class="w-full bg-ht-primary-bg-fill-90 sticky bottom-0 flex-col justify-start items-center inline-flex md:w-full"
          >
            <slot name="stage-review" />

            <!-- <div
            class="w-full px-4 py-1.5 bg-ht-primary-bg-fill-90 border-t border-ht-border-1 justify-start items-center gap-1 inline-flex md:hidden"
          >
            <div class="w-8 h-8">
              <client-only>
                <Vue3Lottie :animation-data="AIBlob" width="w-8" height="w-8" />
              </client-only>
            </div>

            <FormKit
              type="text"
              label="AI Prompt"
              placeholder="How may I assist you today?"
              outer-class="w-full h-full"
              inner-class="outline-none h-full"
              input-class="px-2 py-0 text-sm"
              autocomplete="off"
            />
          </div> -->

            <div
              v-if="false"
              class="w-full px-4 py-1.5 bg-ht-primary-bg-fill-90 border-t border-ht-border-1 justify-start items-center gap-1 inline-flex md:hidden"
            >
              <!-- TODO: breadcrumbs -->
              <HBreadcrumb>Agency</HBreadcrumb>
              <HBreadcrumb>Client</HBreadcrumb>
              <HBreadcrumb>Projects</HBreadcrumb>
              <HBreadcrumb class="w-32 truncate"
                >A very long project name for Pepsi at the World
                Cup</HBreadcrumb
              >
              <HBreadcrumb>Current stage</HBreadcrumb>
              <HBreadcrumb :is-current="true">Project Objectives</HBreadcrumb>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Panel Wrapper -->
      <slot name="rightPanel"></slot>
    </div>
    <HConfirmModal />
  </div>
</template>

<style lang="scss" scoped>
.parent {
  contain: content;
}

.in-review-border {
  @apply border-x-[1.5px] border-[#B7B7DF]/60 dark:border-[#424268];
}
</style>
